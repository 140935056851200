var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td colspan=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"colspan") || (depth0 != null ? lookupProperty(depth0,"colspan") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"colspan","hash":{},"data":data,"loc":{"start":{"line":1,"column":13},"end":{"line":1,"column":24}}}) : helper)))
    + "\" class=\"text-center\">\n    <div class=\"loader-wrapper\"><div class=\"loader\"></div></div>\n    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"loading_product",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":42}}}))
    + "\n</td>";
},"useData":true});