var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/tofixed.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"default_ppu") : depth0),2,{"name":"common$tofixed","hash":{},"data":data,"loc":{"start":{"line":133,"column":18},"end":{"line":133,"column":50}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<html>\n<head>\n    <meta charset=\"UTF-8\">\n    <style>\n        /* http://meyerweb.com/eric/tools/css/reset/\n           v2.0 | 20110126\n           License: none (public domain)\n        */\n\n        html, body, div, span, applet, object, iframe,\n        h1, h2, h3, h4, h5, h6, p, blockquote, pre,\n        a, abbr, acronym, address, big, cite, code,\n        del, dfn, em, img, ins, kbd, q, s, samp,\n        small, strike, strong, sub, sup, tt, var,\n        b, u, i, center,\n        dl, dt, dd, ol, ul, li,\n        fieldset, form, label, legend,\n        table, caption, tbody, tfoot, thead, tr, th, td,\n        article, aside, canvas, details, embed,\n        figure, figcaption, footer, header, hgroup,\n        menu, nav, output, ruby, section, summary,\n        time, mark, audio, video {\n            margin: 0;\n            padding: 0;\n            border: 0;\n            font-size: 100%;\n            font: inherit;\n            vertical-align: baseline;\n        }\n        /* HTML5 display-role reset for older browsers */\n        article, aside, details, figcaption, figure,\n        footer, header, hgroup, menu, nav, section {\n            display: block;\n        }\n        body {\n            line-height: 1;\n        }\n        ol, ul {\n            list-style: none;\n        }\n        blockquote, q {\n            quotes: none;\n        }\n        blockquote:before, blockquote:after,\n        q:before, q:after {\n            content: '';\n            content: none;\n        }\n        table {\n            border-collapse: collapse;\n            border-spacing: 0;\n        }\n\n        /**\n         * fonts\n         */\n        /* roboto-mono-regular - latin */\n        @font-face {\n            font-family: 'price_tag_font';\n            font-style: normal;\n            font-weight: 400;\n            src: local('price_tag_font'), local('RobotoMono-Regular'),\n            url('"
    + alias4(((helper = (helper = lookupProperty(helpers,"price_tag_font_url") || (depth0 != null ? lookupProperty(depth0,"price_tag_font_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price_tag_font_url","hash":{},"data":data,"loc":{"start":{"line":63,"column":17},"end":{"line":63,"column":39}}}) : helper)))
    + "') format('truetype'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */\n        }\n\n        /* REAL STYLING IS FROM HERE */\n        .price_tag {\n            position: absolute;\n            width: 353px; /* 368-15*/\n            height:255px;\n            overflow: hidden;\n\n            padding: 5px;\n            padding-right: 10px;\n            margin-top: 0;\n            margin-left: 5px;\n\n            font-family: 'price_tag_font', monospace;\n        }\n\n        .price_tag .price-discount {\n            font-size: 2em;\n            font-weight: bold;\n            text-decoration: line-through;\n            padding-left: 10px;\n        }\n        .price_tag .price {\n            font-size: 3.5em;\n            font-weight: bold;\n            text-align: right;\n            margin-top: -5px;\n        }\n\n        .title {\n            font-weight: bold;\n            font-size: 22px;\n            max-height: 80px;\n            overflow: hidden;\n            margin-top: -6px;\n            line-height: 29px;\n            padding-left: 10px;\n        }\n\n        .barcode-container {\n            position: absolute;\n            bottom: 0;\n            width: 100%;\n            text-align: center;\n        }\n        .barcode-container .barcode {\n            width: 100%;\n            height: 60px;\n            margin-bottom: -5px;\n            padding-left: 1px;\n        }\n        .barcode-container .barcode-sku {\n            font-size: 0.7em;\n            height: 1em;\n            overflow: hidden;\n        }\n        .barcode-container .barcode-text {\n            font-size: 1.3em;\n            height: 1em;\n            overflow: hidden;\n        }\n    </style>\n</head>\n<body>\n<div class=\"price_tag\">\n\n    <div class=\"price-discount\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_discount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":8},"end":{"line":134,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"price\">\n        "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/tofixed.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),2,{"name":"common$tofixed","hash":{},"data":data,"loc":{"start":{"line":137,"column":8},"end":{"line":137,"column":34}}}))
    + "\n    </div>\n    <div class=\"title\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"product_name") || (depth0 != null ? lookupProperty(depth0,"product_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"product_name","hash":{},"data":data,"loc":{"start":{"line":140,"column":8},"end":{"line":140,"column":24}}}) : helper)))
    + "\n    </div>\n\n    <div class=\"barcode-container\">\n        <div class=\"barcode-sku\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"sku") || (depth0 != null ? lookupProperty(depth0,"sku") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sku","hash":{},"data":data,"loc":{"start":{"line":145,"column":12},"end":{"line":145,"column":19}}}) : helper)))
    + "\n        </div>\n        <img class=\"barcode\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"barcode") || (depth0 != null ? lookupProperty(depth0,"barcode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"barcode","hash":{},"data":data,"loc":{"start":{"line":147,"column":34},"end":{"line":147,"column":45}}}) : helper)))
    + "\" >\n        <div class=\"barcode-text\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"barcode_text") || (depth0 != null ? lookupProperty(depth0,"barcode_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"barcode_text","hash":{},"data":data,"loc":{"start":{"line":149,"column":12},"end":{"line":149,"column":28}}}) : helper)))
    + "\n        </div>\n    </div>\n\n</div>\n</body>\n</html>";
},"useData":true});