var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"btn btn-regular btn-info\" data-action=\"print-multiple\" type=\"button\">\n                    <i class=\"fas fa-print\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_multiple_prices",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":49},"end":{"line":18,"column":93}}}))
    + "\n                </button>\n                <div data-region=\"print-multiple-price-tags\" class=\"hidden\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"search-container designer-box rounded full-width\">\n    <form class=\"form\" onsubmit=\"return false;\" data-action=\"form\">\n        <div class=\"input-group\">\n            <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"type_here_to_search_for_a_product",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":65},"end":{"line":4,"column":121}}}))
    + "\" data-action=\"search\" data-ui=\"search\">\n            <span class=\"input-group-btn\">\n\n                <button class=\"btn btn-regular btn-primary\" type=\"button\" data-action=\"clear\">\n                    <i class=\"fas fa-times\"></i>\n                </button>\n\n                <button class=\"btn btn-regular btn-primary\" type=\"submit\">\n                    <i class=\"fas fa-search\"></i>\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"search",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":49}}}))
    + "\n                </button>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasLpPrinter") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </span>\n        </div>\n    </form>\n</div>\n\n<div class=\"product-container designer-box rounded\">\n    <div class=\"loader-wrapper\" data-name=\"main\"><div class=\"loader\"></div></div>\n\n    <div data-region=\"products\">\n        <p class=\"text-center\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"search_for_products_using_the_search_bar",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":31},"end":{"line":31,"column":94}}}))
    + "</p>\n    </div>\n\n    <div class=\"more full-child-height\">\n        <button class=\"btn btn-primary btn-block\" data-action=\"more\" data-ui=\"more\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"load_more",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":84},"end":{"line":35,"column":116}}}))
    + "</button>\n        <div class=\"loader-wrapper\" data-name=\"more\"><div class=\"loader\"></div></div>\n    </div>\n</div>";
},"useData":true});