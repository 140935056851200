var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-primary btn-block \" data-action=\"start-printing\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pricetags",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":84},"end":{"line":15,"column":116}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-primary btn-block \" data-action=\"start-printing-stickers\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"stickers",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":93},"end":{"line":18,"column":124}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div  class=\"alert alert-info\">\n    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"one_barcode_or_search_query_per_line",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":63}}}))
    + "\n</div>\n\n<div class=\"form-group\">\n    <textarea id=\"barcodes\" rows=\"20\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"example",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":72},"end":{"line":6,"column":102}}}))
    + ":\n0725272730706\n9876543210201\n0123456789783\"></textarea>\n</div>\n\n<div class=\"form-group\">\n    <div class=\"buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPriceTag") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSticker") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});