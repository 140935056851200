var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div data-ui=\"subtotal\" class=\"designer-box rounded full-child-height\">\n    <button class=\"btn btn-info\" data-action=\"sub\">\n        <i class=\"fas fa-arrow-left\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":69}}}))
    + "\n    </button>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div data-ui=\"subtotal\" class=\"designer-box rounded\">\n    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"subtotal",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":41}}}))
    + "</span>\n    <b></b>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"show_back_button") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "")
    + "<div data-ui=\"discount\" class=\"full-child-height\"></div>\n<div data-ui=\"total\" class=\"designer-box rounded\">\n    <span></span>\n    <b class=\"total-price\" ></b>\n</div>\n";
},"useData":true});