var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n</td>\n<td>\n    "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"flat_product") : depth0)) != null ? lookupProperty(stack1,"product") : stack1)) != null ? lookupProperty(stack1,"sku") : stack1), depth0))
    + "\n</td>\n<td>\n    "
    + alias2(((helper = (helper = lookupProperty(helpers,"stockAmount") || (depth0 != null ? lookupProperty(depth0,"stockAmount") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"stockAmount","hash":{},"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":19}}}) : helper)))
    + "\n    &nbsp;\n    <i class=\"fas fa-warehouse "
    + alias2(((helper = (helper = lookupProperty(helpers,"stockClass") || (depth0 != null ? lookupProperty(depth0,"stockClass") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"stockClass","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":45}}}) : helper)))
    + "\"></i>\n</td>\n<td class=\"text-right\">\n    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"product_price") : depth0)) != null ? lookupProperty(stack1,"ppu_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":79}}}))
    + "\n</td>\n";
},"useData":true});