var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-link text-primary\" data-ui=\"product-details\"><i class=\"fas fa-info-circle fa-2x\"></i></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"head\">\n    <span>\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":16}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInfoButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n    <button class=\"btn btn-default\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"content\">\n    <form onsubmit=\"return false;\" method=\"post\" class=\"form-bordered-compact\">\n\n        <div class=\"form-group\">\n            <label class=\"control-label\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":75}}}))
    + "</label>\n            <div data-region=\"description\">\n            </div>\n        </div>\n\n        <div  class=\"form-group\" data-region=\"measurements\"></div>\n        <div  class=\"form-group\" data-region=\"serials\"></div>\n\n    </form>\n</div>\n<button class=\"btn btn-default cancel\" data-action=\"close\"><i class=\"fa fa-times\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":87},"end":{"line":24,"column":116}}}))
    + "</button>\n<button class=\"btn btn-success save\" data-action=\"save\"><i class=\"fa fa-check\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"save",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":84},"end":{"line":25,"column":111}}}))
    + "</button>\n\n<div class=\"hidden\" data-region=\"popup\"></div>\n";
},"useData":true});