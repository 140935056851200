var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ticket\" style=\"color:black;\">\n    <meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\">\n    <span class=\"html-content\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n    </span>\n    <style>\n        .html-content {\n            width: 72mm;/*printer paper width*/\n            font-size: 1.5em;\n            font-family: monospace;\n        }\n        .html-content table {\n            width: 100%;\n            margin-bottom: 40px;\n            border-collapse: collapse;\n        }\n        .html-content table thead {\n            font-weight: bold;\n            font-size: 1.5em;\n        }\n        .html-content td {\n            border: 2px solid black;\n            font-size: 1.4em;\n        }\n        .html-content td:not(:first-child) {\n            width: 190px;\n            text-align: right;\n            padding-right: 2px;\n        }\n        .html-content tr td:not(:first-child) {\n            white-space: nowrap;\n        }\n        </style>\n</div>";
},"useData":true});